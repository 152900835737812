// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";
@import "material-dashboard-pro-react/fixed-plugin";

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
   border-color: #454342 !important;
}

.MuiInputBase-root.Mui-disabled {
   color: #000000de !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
   text-align: center !important;
   padding   : 12px 5px !important;
}

.ReactTable .rt-td {
   font-weight: 400 !important;
}

.ReactTable .rt-th {
   font-weight: bold !important;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .-pagination .-pageJump input {
   background-image: none;
   padding         : 5px 7px !important;

   &:focus {
      background-image: none;
   }
}

.iconEdit {
   color: #4caf50;
}

.iconRemove {
   color: #f44336
}

.ReactTable .rt-thead.-header {
   box-shadow   : none !important;
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.MuiAlert-standardSuccess {
   background-color: #b7dcb7 !important;
}

body {
   .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: #00acc1 !important;
   }

   .MuiSwitch-thumb {
      border-color: #00acc1 !important;
   }
}